body {
	margin: 0;
	overflow-x: hidden;
}
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #757474;
	border-radius: 20px;
}
